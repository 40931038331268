/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./styles";
import Container from "../../components/home/container";
import Header from "../../components/home/header";
import Footer from "../../components/home/footer";
import { getTermo } from "../../redux/termOfUse/actions";
import useCheckIsMobileScreen from "../../hooks/useCheckIsMobileScreen";
import { createEventHomeDataLayer } from "../../services/dataLayers";

const Termo = () => {
  const dispatch = useDispatch();
  const { termoData } = useSelector((state) => state.termOfUse);
  const isMobile = useCheckIsMobileScreen();
  const [width, setWidth] = useState("100%");
  const [height, setHeight] = useState("auto");
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setWidth("100%");
      setHeight("auto");
      dispatch(getTermo());
      createEventHomeDataLayer("Termo");
    }
  }, []);

  if (!termoData) return;

  return (
    <S.BaseContainer>
      <Container
        width={width}
        height={height}
        padding="0px"
        gap="0px"
        opacity="0px"
        colorcontainer="dark90"
      >
        <Header showClose={false}></Header>
        <S.Wrapper
          padding={isMobile ? "16px 16px 16px 16px" : "128px 128px 128px 128px"}
          gap={isMobile ? "16px" : "64px"}
        >
          <S.Text
            color="darkGray"
            fontName={isMobile ? "mobileHeader1" : "titleDesktop"}
          >
            {termoData.title}
          </S.Text>
          <S.Text
            color="darkGray"
            fontName={isMobile ? "mobileBody" : "titleDesktop2"}
          >
            {parse(termoData.html_body)}
          </S.Text>
        </S.Wrapper>
        <Footer></Footer>
      </Container>
    </S.BaseContainer>
  );
};

export default Termo;
