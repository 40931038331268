export const breakpointSizes = {
  // XXS: 360,
  // XS: 768,
  // MD: 991,
  // LG: 1024,
  // XL: 1200,
  // XXL: 1440,
  minXXS: 360,
  maxXXS: 361,
  minXS: 768,
  maxXS: 769,
  minMD: 991,
  maxMD: 992,
  minLG: 1024,
  maxLG: 1025,
  minXL: 1200,
  maxXL: 1201,
  minXXL: 1440,
  maxXXL: 1441,
};

const breakpoints = {};

Object.entries(breakpointSizes).forEach(([sizeName, size]) => {
  breakpoints[`min${sizeName}`] = `(min-width: ${size}px)`;
  breakpoints[`max${sizeName}`] = `(max-width: ${size + 1}px)`;
});

export default breakpoints;
