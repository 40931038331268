import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% { background-position: 0% 100% }
  50% { background-position:-50% 0% }
  100% { background-position:-1000% 100% }
`;

export const Block = styled.div`
  background: #e0e0e0;
  display: ${({ display }) => display};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  &:after {
    content: '';
    background: #e0e0e0;
    background: linear-gradient(
      310deg,
      #e0e0e0 0%,
      #f0f0f0 50%,
      #e0e0e0 70%
    );
    background-size: 100% 800%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    animation: ${animation} 1.5s ease infinite;
  }
`;
