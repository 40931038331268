import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPhone } from "../../../redux/talkToUs/actions";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import { callWhatsApp } from "../../../services/talkToUs";

const DropdownItem = ({ item }) => {
  const isMobile = useCheckIsMobileScreen();
  const dispatch = useDispatch();
  const { phone } = useSelector((state) => state.talkToUs);
  const navigate = useNavigate();
  const chevronDown = "/images/chevron-down.svg";
  const chevronUp = "/images/chevron-up.svg";
  const [height, setHeight] = useState(0);
  const [chevron, setChevron] = useState(chevronDown);
  const [isExpanded, setIsExpanded] = useState();

  const contentHeight = isExpanded ? 800 : 0;

  const handleExpandToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
    setChevron(isExpanded ? chevronDown : chevronUp);
  }, [isExpanded]);

  const onClick = async (submenu, item) => {
    if (!isMobile) {
      onClickSubMenuDeskTop();
    }
    if (item.slug === "fale-conosco") {
      await dispatch(getPhone());
      callWhatsApp(isMobile, phone);
      return;
    }
    navigate("/product", {
      state: {
        product: submenu.slug,
        pivot: submenu.pivot,
      },
    });
  };

  const onClickSubMenuDeskTop = () => {
    if (item.products.length > 1) {
      setHeight(height === "228px" ? "0px" : "228px");
    }
  };

  const onClickMouseLeave = () => {
    setHeight("0px");
  };

  const getMenuMobile = () => {
    return (
      <S.Container padding="16px 0px 0px 0px">
        {item.products.length > 1 && (
          <>
            <S.Title
              padding="0px 0px 0px 0px"
              width="auto"
              onClick={handleExpandToggle}
            >
              <S.Text>{item.name}</S.Text>
              {item.products.length > 0 && <S.Image src={chevron} />}
            </S.Title>
            <S.ContentWrapper maxHeight={contentHeight} height={contentHeight}>
              {item.products.map((submenu, key) => {
                return (
                  <S.Content key={key} onClick={() => onClick(submenu, item)}>
                    <S.TextSubMenu>{submenu.name}</S.TextSubMenu>
                  </S.Content>
                );
              })}
            </S.ContentWrapper>
            <S.Line></S.Line>
          </>
        )}
        {item.products.length <= 1 && (
          <>
            <S.Title
              padding="0px 0px 0px 0px"
              width="auto"
              onClick={() => onClick(item, item)}
            >
              <S.Text>{item.name}</S.Text>
              {item.products.length > 1 && <S.Image src={chevron} />}
            </S.Title>
            <S.Line></S.Line>
          </>
        )}
      </S.Container>
    );
  };

  const getMenuDeskTop = () => {
    return (
      <S.Container
        padding="8px 0px 0px 32px"
        height={height}
        onMouseLeave={() => onClickMouseLeave()}
      >
        {item.products.length > 1 && (
          <>
            <S.Title
              padding="0px 0px 0px 0px"
              width="auto"
              onClick={() => onClickSubMenuDeskTop(item)}
            >
              <S.Text>{item.name}</S.Text>
            </S.Title>
            <S.ContentWrapper maxHeight={height} height={height}>
              {item.products.map((submenu, key) => {
                return (
                  <S.Content key={key} onClick={() => onClick(submenu, item)}>
                    <S.TextSubMenu>{submenu.name}</S.TextSubMenu>
                  </S.Content>
                );
              })}
            </S.ContentWrapper>
          </>
        )}
        {item.products.length <= 1 && (
          <S.Title
            padding="0px 0px 0px 0px"
            width="300px"
            onClick={() => onClick(item, item)}
          >
            <S.Text>{item.name}</S.Text>
          </S.Title>
        )}
      </S.Container>
    );
  };

  return (
    <>
      {isMobile && getMenuMobile()}
      {!isMobile && getMenuDeskTop()}
    </>
  );
};

export default DropdownItem;
