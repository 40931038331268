import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const Skeleton = ({
  width,
  height,
  position,
  top,
  left,
  bottom,
  right,
  display,
  margin,
  padding
}) => {
  return (
    <S.Block
      data-testid="skeleton"
      width={width}
      height={height}
      position={position}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      display={display}
      margin={margin}
      padding={padding}
    />
  );
};

Skeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  display: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string
};

Skeleton.defaultProps = {
  width: '10rem',
  height: '10rem',
  position: 'relative',
  top: 'auto',
  left: 'auto',
  bottom: 'auto',
  right: 'auto',
  display: 'block',
  margin: '0',
  padding: '0'
};

export default Skeleton;
