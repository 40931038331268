import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

const Container = ({
  width,
  height,
  padding,
  gap,
  opacity,
  colorcontainer,
  children,
}) => {
  return (
    <S.Container
      width={width}
      height={height}
      padding={padding}
      gap={gap}
      opacity={opacity}
      colorcontainer={colorcontainer}
    >
      {children}
    </S.Container>
  );
};

Container.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  gap: PropTypes.string,
  opacity: PropTypes.string,
  colorcontainer: PropTypes.string,
  children: PropTypes.object
};

export default Container;
