import styled from "styled-components";
export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 8px 8px;
  margin: 0px;
  width: 100%;
  height: auto;
`;

export const Text = styled.text`
  color: ${({ theme, color }) => theme.color[color]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
  padding: 4px 0px 4px 0px;
`;

export const Input = styled.input`
  border: 1px solid ${({ theme, inputColor }) => theme.color[inputColor]};
  border-right:0px;
  border-top:0px;
  border-left:0px;
  outline: none;
  // border-color: ${({ theme }) => theme.color.white};
  color: ${({ theme, inputColor }) => theme.color[inputColor]};
  background-color: ${({ theme, backgroundColor }) => theme.color[backgroundColor]};
  width: 100%;
  height: 40px;
`;
