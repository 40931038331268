import initialState from "../initialState";
import types from "../types";

const homeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_HOME:
      sessionStorage.setItem('concreto.auth', JSON.stringify(payload.cookie));
      return {
        ...state,
        homeData: payload.home,
        cookiesData: payload.cookie
      };
    default:
      return state;
  }
};
export default homeReducer;
