import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import ConfigAxios from './services/axios';
// import Header from './components/Header';
// import Modal from './components/Modal';
const App = () => {
  ConfigAxios();
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <Router />
      {/* <Modal /> */}
    </BrowserRouter>
  );
};

export default App;
