/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  validateEmail,
  checkCpf,
  checkCnpj,
  isValidDate,
} from "../../../utils/validate";
import {
  formatPhone,
  formatDocumentCnpjCpf,
  formatCurrency,
  formatDate,
} from "../../../utils/format";

import * as S from "./styles";

const ShortText = ({
  idQuestion,
  title,
  index,
  value,
  validation,
  setError,
  setValueQuestion,
  isOptional,
  backgroundColor = "dark",
  inputColor = "white",
  textColor = "white"
}) => {
  const [messageError, setMessageError] = useState("");
  const [valueField, setValueField] = useState(
    value !== undefined ? value : "",
  );
  const idField = "field" + index;

  useEffect(() => {
    setValueField(value);
  }, [idQuestion]);

  useEffect(() => {
    if (getTypeField() === "decimal" && valueField) {
      if (valueField.length > 6) setCursor();
    }
  }, [valueField]);

  const getTypeField = () => {
    const validations = validation.split(",");
    return validations[0];
  };

  const handleBlur = () => {

    if (!isEmpty() && valueField !== undefined && valueField.trim() !== "") {
      switch (getTypeField()) {
        case "e-mail": {
          verifyFieldEmail();
          break;
        }
        case "phone": {
          verifyFieldPhone();
          break;
        }
        case "cpf": {
          verifyFieldCPF();
          break;
        }
        case "cnpj": {
          verifyFieldCNPJ();
          break;
        }
        case "birth": {
          verifyFieldBirth();
          break;
        }
        case "year": {
          verifyFieldYear();
          break;
        }
        default: {
          return;
        }
      }
    }
  };

  const isEmpty = () => {
    if (!isOptional && (valueField === undefined || valueField.length === 0)) {
      setMessageError(title + " não informado");
      setError(true, index);
      focus();
      return true;
    }
    setMessageError("");
    setError(false, index);
    return false;
  };

  const verifyFieldYear = () => {
    if (valueField.length < 4) {
      setMessageError("Ano inválido, informe com 4 dígitos");
      setError(true, index);
      focus();
    } else {
      setMessageError("");
      setError(false, index);
    }
  };

  const verifyFieldCNPJ = () => {
    if (!checkCnpj(valueField)) {
      setMessageError("CNPJ inválido");
      setError(true, index);
      focus();
    } else {
      setMessageError("");
      setError(false, index);
    }
  };

  const verifyFieldCPF = () => {
    if (!checkCpf(valueField)) {
      setMessageError("CPF inválido");
      setError(true, index);
      focus();
    } else {
      setMessageError("");
      setError(false, index);
    }
  };

  const verifyFieldPhone = () => {
    if (valueField.length < 14) {
      setMessageError("Telefone inválido");
      setError(true, index);
      focus();
    } else {
      setMessageError("");
      setError(false, index);
    }
  };

  const verifyFieldBirth = () => {
    if (valueField.length < 10) {
      setMessageError("Data nascimento inválido");
      setError(true, index);
      focus();
      return;
    }

    if (!isValidDate(valueField, true)) {
      setMessageError("Data nascimento inválido");
      setError(true, index);
      focus();
      return;
    }

    setMessageError("");
    setError(false, index);
  };

  const focus = () => {
    const textbox = document.getElementById(idField);
    textbox.focus();
  };

  const setCursor = () => {
    const textbox = document.getElementById(idField);
    textbox.focus();
    const length = textbox.value.length;
    textbox.setSelectionRange(length - 3, length - 3);
  };

  const verifyFieldEmail = () => {
    if (!validateEmail(valueField)) {
      setMessageError("Email inválido");
      setError(true, index);
      focus();
    } else {
      setMessageError("");
      setError(false, index);
    }
  };

  const handleChange = (value) => {
    let field = "";
    switch (getTypeField()) {
      case "phone": {
        field = formatPhone(value);
        break;
      }
      case "birth": {
        field = formatDate(value);
        break;
      }
      case "cpf": {
        field = formatDocumentCnpjCpf(value, true);
        break;
      }
      case "cnpj": {
        field = formatDocumentCnpjCpf(value, false);
        break;
      }
      case "decimal": {
        field = formatCurrency(value, true);
        break;
      }
      default: {
        field = value;
      }
    }

    setValueField(field);
    setValueQuestion(idQuestion, field);
  };

  const onKeyPress = (e) => {
    switch (getTypeField()) {
      case "phone": {
        fieldNumber(e, 15);
        break;
      }
      case "birth": {
        fieldNumber(e, 10);
        break;
      }
      case "cpf": {
        fieldNumber(e, 14);
        break;
      }
      case "cnpj": {
        fieldNumber(e, 18);
        break;
      }
      case "decimal": {
        fieldNumber(e, 20);
        break;
      }
      case "year": {
        fieldNumber(e, 4);
        break;
      }
    }
  };

  const fieldNumber = (e, length) => {
    if (e.charCode < 48 || e.charCode > 57) {
      e.preventDefault();
      return false;
    }
    if (e.target.value.length >= length) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <S.ContainerText key={index}>
      <S.Text color={textColor} fontName="bodyTextNegrito">
        {title}
      </S.Text>
      <S.Input
        id={idField}
        value={valueField}
        onBlur={handleBlur}
        backgroundColor={backgroundColor}
        inputColor={inputColor}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={(e) => onKeyPress(e)}
      ></S.Input>
      <S.Text color="red" fontName="bodyText2">
        {messageError}
      </S.Text>
    </S.ContainerText>
  );
};

ShortText.propTypes = {
  idQuestion: PropTypes.number,
  title: PropTypes.string,
  index: PropTypes.string,
  value: PropTypes.string,
  validation: PropTypes.string,
  isOptional: PropTypes.bool,
  setError: PropTypes.func,
  setValueQuestion: PropTypes.func,
  backgroundColor: PropTypes.string,
  inputColor: PropTypes.string,
  textColor: PropTypes.string
};

export default ShortText;
