import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  flex-direction:  ${({ flexDirection }) => flexDirection || "column"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "368px"};
  padding: ${({ padding }) => padding || "24px 16px 24px 16px"};
  gap: ${({ gap }) => gap || "24px"};
  background-color: ${({ theme, color }) => theme.color[color]};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  flex-direction:  ${({ flexDirection }) => flexDirection || "column"};
  background-color: ${({ theme }) => theme.color.dark};
  padding: ${({ padding }) => padding || "0px 0px 0px 0px"};
  gap: ${({ gap }) => gap || "16px"};
  background-color: ${({ theme, color }) => theme.color[color]};
`;

export const Text = styled.text`
  color: ${({ theme }) => theme.color.white};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const Image = styled.img`
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
`;

export const ContainerInformation = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerSocialMidia = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  width: 76px;
  height: 24px;
  gap: 10px;
  opacity: 0px;
`;

export const Line = styled.hr`
  border-color: ${({ theme }) => theme.color.white};
  box-sizing: border-box;
  width: 100%;
`;

export const HeadLink = styled.a`
  text-decoration: none;
`;

