import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  flex-direction:  ${({ flexDirection }) => flexDirection || "column"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "558px"};
  padding: ${({ padding }) => padding || "24px 16px 24px 16px"};
  gap: 40px;
  background-color: ${({ theme }) => theme.color.dark};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-direction:  ${({ flexDirection }) => flexDirection || "column"};
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  gap: ${({ gap }) => gap || "40px"};
  background-color: ${({ theme }) => theme.color.dark};
  align-self: ${({ alignSelf }) => alignSelf || "auto"};
`;

export const Text = styled.text`
  color: ${({ theme }) => theme.color.white};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.color.beige};
  color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.beige};
  width: 100%;
  height: 40px;
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Image = styled.img`
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
`;

export const ContainerTextLoad = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: "center";
  align-content: "center";
  gap: 8px;
  padding: 0px;
  margin: 0px;
`;
