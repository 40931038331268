import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import { createEventDataLayer } from "../../../services/dataLayers";

const SolutionsItem = ({ color, title, body, link, img, item }) => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobileScreen();

  const onClick = () => {
    const product = item.destination_route.replace("/produto/", "");
    createEventDataLayer("Saiba mais – <" + item.type + ">", "<" + item.title + ">");
    navigate('/product', {
      state: {
        product: product
      },
    });
  };

  const getMobile = () => {
    return (
      <S.Container height="160px">
        <S.Wrapper height="128px" width="170%" gap="8px">
          <S.Text fontName="bodyTextNegrito">{title}</S.Text>
          <S.Text fontName="mobileBody">{body}</S.Text>
          <S.SaibaMais onClick={() => onClick()}>
            <S.Text fontName="bodyTextNegrito">Saiba mais</S.Text>
          </S.SaibaMais>
        </S.Wrapper>
        <S.Wrapper height="128px">
          <S.Image src={img} width="128px" height="128px" />
        </S.Wrapper>
      </S.Container>
    );
  };
  const getDesktop = () => {
    return (
      <S.Container height="auto">
        <S.Wrapper padding="16px 16px 16px 16px" height="auto" width="100%">
          <S.Text fontName="bodyText1Bold">{title}</S.Text>
          <S.Text fontName="bodyText1">{body}</S.Text>
          <S.SaibaMais onClick={() => onClick()}>
            <S.Text fontName="bodyText2Bold">Saiba mais</S.Text>
          </S.SaibaMais>
        </S.Wrapper>
        <S.Wrapper>
          <S.Image src={img} width="100%" height="auto" />
        </S.Wrapper>
      </S.Container>
    );
  };
  return isMobile ? getMobile() : getDesktop();
};

SolutionsItem.propTypes = {
  color: PropTypes.string,
};

export default SolutionsItem;
