import axios from "axios";
import types from "../types";

export const getPhone = () => {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_PHONE
    });
    const url = `${process.env.REACT_APP_BFF}talktous`;
    try {
      axios
        .get(url, { timeout: 300000, withCredentials: false })
        .then((response) => {
          dispatch({
            type: types.GET_PHONE,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };
};
