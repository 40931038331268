import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: column;
  top: ${({ top }) => top || "0px"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "500px"};
  gap: ${({ gap }) => gap || "24px"};
  opacity: 0px;
  background-image: url(${({ urlImg }) => urlImg});
  padding: ${({ padding }) => padding || "16px 16px 16px 16px"};
  background-size: cover;
  background-position: center;

  @media screen and (${({ theme }) => theme.breakpoints.minXS}) {
    width: 1632px;
    height: 88px;
    padding: 16px 24px 16px 24px;
    border-radius: 4px 0px 0px 0px;
    background-size: 1632px;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px 16px;
  margin: 0px;
  gap:16px;
  width: 100%;
  text-shadow: 1px 1px 1px black;
  height: ${({ height }) => height || "159px"};
`;

export const ContainerText1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  margin: 0px;
  width: ${({ width }) => width || "70%"};
  height: ${({ height }) => height || "55%"};
`;

export const Text = styled.text`
  color: ${({ theme, color }) => theme.color[color]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const ContainerText2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || "0px 0px 0px 0px"};
  margin: 0px;
  width: 70%;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.white};
  border: 0px;
  width: 100%;
  height: ${({ height }) => height || "64px"};
  gap: 10px;
  border-radius: 8px 8px 8px 8px;
  opacity: 0px;
  box-shadow: 2px 2px #888888;
  cursor: pointer;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  gap: ${({ gap }) => gap || "16px"};
  width: ${({ width }) => width || "100%"};
  height: 176px;
  padding: 0px 16px 0px 16px;
`;

export const TextButton = styled.text`
  color: ${({ theme }) => theme.color.dark};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
`;

export const ContainerTextLoad = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: "center";
  align-content: "center";
  gap: 8px;
  padding: 0px;
  margin: 0px;
`;
