export const getCookies = () => {
  const cookies = document.cookie.split('; ');
  const cookieObject = {};
  cookies.forEach(cookie => {
    const [key, value] = cookie.split('=');
    cookieObject[key] = value;
  });
  return cookieObject.sessionId;
};

export default getCookies;
