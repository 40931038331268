import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;
  height: auto;
  min-height: ${({ minHeight }) => minHeight || "480px"};
  background: ${({ theme }) => theme.color.dark};
  gap:16px;
  padding: 8px 12px 12px 12px;
`;

export const Button = styled.button`
  background: ${({ theme, backgroudColor }) => theme.color[backgroudColor]};
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  border: 1px solid
    ${({ theme, borderColor }) => theme.color[borderColor]};
  width: 100%;
  height: 35px;
  border-radius: 8px 8px 8px 8px;
`;

export const Text = styled.text`
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;
