/* eslint-disable import/no-anonymous-default-export */
export default {
  GET_OPEN: 'GET_OPEN',
  GET_CLOSE: 'GET_CLOSE',
  SET_STEP: 'SET_STEP',
  SET_NUMBERSTEP: 'SET_NUMBERSTEP',
  SET_HASERROR: 'SET_HASERROR',
  SET_QUESTIONNAIRE: 'SET_QUESTIONNAIRE',
  SET_CLEAR_QUESTIONNAIRE: 'SET_CLEAR_QUESTIONNAIRE',
  SET_OPTION: 'SET_OPTION',
  SET_NEXTQUESTIONID: 'SET_NEXTQUESTIONID',
  SET_PIVOT: 'SET_PIVOT',
  SET_QUESTIONSDRAW: 'SET_QUESTIONSDRAW'
};
