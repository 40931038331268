import { combineReducers } from 'redux';
import quizReducer from '../quiz/reducer';
import menuReducer from '../menu/reducer';
import homeReducer from '../home/reducer';
import ipReducer from '../ip/reducer';
import termOfUseReducer from '../termOfUse/reducer';
import talkToUsReducer from '../talkToUs/reducer';
import productReducer from '../product/reducer';

const reducers = combineReducers({
  quiz: quizReducer,
  menu: menuReducer,
  home: homeReducer,
  ip: ipReducer,
  termOfUse: termOfUseReducer,
  talkToUs: talkToUsReducer,
  product: productReducer
});

export default reducers;
