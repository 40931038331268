import styled from 'styled-components';

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: Hug (3,450.75px)px;
  padding: 0;
  margin: 0;
  gap: 104px;
  opacity: 0px;
`;

// export const SecondContainer = styled.div`
//   width: 1920px;
//   height: 304px;
//   padding: 0px 144px 0px 144px;
//   gap: 40px;
//   opacity: 0px;
//   background: ${({ theme }) => theme.color.yellow};
// `;

// export const Button = styled.button`
//   color: #BF4F74;
//   font-size: 1em;
//   margin: 1em;
//   padding: 0.25em 1em;
//   border: 2px solid #BF4F74;
//   border-radius: 3px;
// `;

// // A new component based on Button, but with some override styles
// export const TomatoButton = styled(Button)`
//   color: tomato;
//   border-color: tomato;
//   background: ${({ theme }) => theme.color.forest};
// `;

// // export const Button = styled(ButtonBase)`
// //   min-width: 13rem;
// // `;

// export const Button2 = styled(Button)`
//   /* Adapt the colors based on primary prop */
//   background: ${props => props.cor ? "#BF4F74" : "white"};
//   color: ${props => props.cor ? "white" : "#BF4F74"};

//   font-size: 1em;
//   margin: 1em;
//   padding: 0.25em 1em;
//   border: 2px solid #BF4F74;
//   border-radius: 3px;
// `;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
