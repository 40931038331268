import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;
  height: 610px;
  background: ${({ theme }) => theme.color.dark};
  gap:16px;
  padding: 8px 16px 8px 16px;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-end"};
  width: 100%;
  height: 10%;
  background: ${({ theme }) => theme.color.dark};
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  width: 100%;
  height:  ${({ height }) => height};
  background: ${({ theme }) => theme.color.dark};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
`;

export const Button = styled.button`
  background: ${({ theme, backgroudColor }) => theme.color[backgroudColor]};
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  border: 1px solid
    ${({ theme, borderColor }) => theme.color[borderColor]};
  width: ${({ width }) => width || "98%"};
  height:  ${({ height }) => height};
  border-radius: 8px 8px 8px 8px;
`;

export const Text = styled.text`
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;
