import styled from "styled-components";

export const ContainerItem = styled.div`
  width: ${({ width }) => width || "390px"};
  height: ${({ height }) => height || "304px"};
  border-radius: 8px 8px 8px 8px;
  border: 0.1rem solid ${({ theme }) => theme.color.dark};
  background-color: ${({ theme }) => theme.color.dark};
  width: ${({ width }) => width || "248px"};
  height: "${({ height }) => height || "100px"}";
`;

export const Image = styled.img`
  width: 25px;
  height: 25px;
  margin-top: -10px;
  margin-left: 10px;
`;

export const ContainerItemIcon = styled.div`
  position: relative;
  top: -0.1em;
  margin-left: 1em;
  display: inline;
  background-color: white;
  width: ${({ width }) => width || "248px"};
  height: ${({ height }) => height || "100px"};
`;

export const Text = styled.text`
  color: ${({ theme, color }) => theme.color[color]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  gap:${({ gap }) => gap || "32px"};
  opacity: 0px;
  padding: ${({ padding }) => padding || "8px 8px 8px 8px"};
`;

export const Line = styled.hr`
  border-color: ${({ theme }) => theme.color.white};
  box-sizing: border-box;
  width: 100%;
`;
