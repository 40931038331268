import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";

const BestChoice = () => {
  const isMobile = useCheckIsMobileScreen();
  const { homeData } = useSelector((state) => state.home);
  const [bestChoiceData, setBestChoiceData] = useState([]);
  // const dollarRefresh = "/images/dollarRefresh.svg";
  // const percent = "/images/percent.svg";
  // const security = "/images/security.svg";
  // const phone = "/images/phone.svg";

  useEffect(() => {
    if (homeData) {
      getBestChoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  const getBestChoice = () => {
    const board = homeData.highlights.filter((x) => x.type === "features");
    setBestChoiceData(board);
  };

  return (
    <S.Container
      padding={isMobile ? "40px 26px 16px 16px" : "70px 64px 0px 128px"}
      height={isMobile ? "230px" : "auto"}
      gap={isMobile ? "10px" : "40px"}
    >
      <S.Text
        fontName={isMobile ? "mobileHeader2" : "buttonDeskTop"}
        padding={isMobile ? "0 0" : "0 0 20px 0"}
      >
        Entenda por que somos sua melhor escolha!
      </S.Text>
      <S.ContainerCarousel
        height={isMobile ? "120px" : "187px"}
        width={isMobile ? "268px" : "auto"}
        gap={isMobile ? "20px" : "40px"}
      >
        <Carousel
          responsive={S.Responsive}
          showDots={true}
          arrows={false}
          dotListClass="dot-list-class"
          partialVisible={true}
          autoPlay
          autoPlaySpeed={3000}
          infinite
          pauseOnHover
          draggable={true}
        >
          {bestChoiceData.map((item, index) => {
            return (
              <S.ContainerItemIcon
                key={index}
                height={isMobile ? "100px" : "157px"}
                width={isMobile ? "248px" : "100%"}
              >
                <S.ContainerItem
                  height={isMobile ? "100px" : "157px"}
                  width={isMobile ? "268px" : "100%"}
                >
                  <S.Image
                    src={item.icon}
                    height={isMobile ? "20px" : "40px"}
                    width={isMobile ? "20px" : "40px"}
                  ></S.Image>
                  <S.ContainerText gap={isMobile ? "10px" : "20px"}>
                    <S.Text
                      fontName={isMobile ? "bodyTextNegrito" : "bodyText1Bold"}
                      padding={"0 0"}
                    >
                      {item.title}
                    </S.Text>
                    <S.Text fontName={isMobile ? "mobileBody" : "bodyText1"}>
                     {item.description}
                    </S.Text>
                  </S.ContainerText>
                </S.ContainerItem>
              </S.ContainerItemIcon>
            );
          })}
        </Carousel>
      </S.ContainerCarousel>
    </S.Container>
  );
};
export default BestChoice;
