import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";

const Customers = () => {
  const { homeData } = useSelector((state) => state.home);
  const isMobile = useCheckIsMobileScreen();
  const [customers, setCustomers] = useState([]);
  // const agvbrasil = "/images/agvbrasil.svg";
  // const araujo = "/images/araujo.svg";
  // const indinvestiment = "/images/indinvestiment.svg";
  // const north = "/images/north.svg";
  // const smartagro = "/images/smartagro.svg";
  // const vic = "/images/vic.svg";
  useEffect(() => {
    if (homeData) {
      const clientes = homeData.highlights.filter((x) => x.type === "clientes");
      setCustomers(clientes);
    }
  }, [homeData]);

  if (customers.length === 0) return;

  return (
    <S.Container
      height={isMobile ? "200px" : "320px"}
      gap={isMobile ? "10px" : "20px"}
      padding={isMobile ? "16px 16px 16px 16px" : "24px 128px 24px 128px"}
    >
      <S.Text>Conheça alguns dos nossos parceiros e clientes</S.Text>
      <S.ContainerCarousel>
        <Carousel
          responsive={S.Responsive}
          showDots={false}
          arrows={false}
          dotListClass="dot-list-class"
          partialVisible={true}
          autoPlay
          autoPlaySpeed={3000}
          infinite
          pauseOnHover
          draggable={true}
        >
          {customers.map((item, key) => {
            return (
              <S.Image
                key={key}
                src={item.image_url}
                height={isMobile ? "50px" : "auto"}
                width={isMobile ? "160px" : "auto"}
              ></S.Image>
            );
          })}
        </Carousel>
      </S.ContainerCarousel>
    </S.Container>
  );
};
export default Customers;
