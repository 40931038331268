import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 62.5%;
    overflow-y: scroll;
  }

  body {
    font-family: 'Nunito', sans-serif;
    ${({ theme }) => ({
      color: theme.color.fakeBlack,
      backgroundColor: theme.color.silver,
    })};
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
`;

export default GlobalStyle;
