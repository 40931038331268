export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const checkCpfCnpj = async (cpfCNPJ) => {
  if (cpfCNPJ.length == 14) {
    return await checkCpf(cpfCNPJ);
  } else if (cpfCNPJ.length == 18) {
    return await checkCnpj(cpfCNPJ);
  } else {
    return false;
  }
};

export const checkCpf = (cpf) => {
  cpf = cpf.trim();
  cpf = cpf.replace(/\./g, "");
  cpf = cpf.replace("-", "");
  cpf = cpf.split("");

  let valueCalcullation1 = 0;
  let valueCalcullation2 = 0;
  let controlVerifyDigit = false;
  let i = 0;
  let auxiliaryControlCalculation = 0;

  for (i = 1; cpf.length > i; i++) {
    if (cpf[i - 1] != cpf[i]) {
      controlVerifyDigit = true;
    }
  }

  if (controlVerifyDigit == false) {
    return false;
  }

  for (
    i = 0, auxiliaryControlCalculation = 10;
    cpf.length - 2 > i;
    i++, auxiliaryControlCalculation--
  ) {
    valueCalcullation1 += cpf[i] * auxiliaryControlCalculation;
  }

  valueCalcullation1 = (valueCalcullation1 * 10) % 11;

  if (valueCalcullation1 == 10) {
    valueCalcullation1 = 0;
  }

  if (valueCalcullation1 != cpf[9]) {
    return false;
  }

  for (
    i = 0, auxiliaryControlCalculation = 11;
    cpf.length - 1 > i;
    i++, auxiliaryControlCalculation--
  ) {
    valueCalcullation2 += cpf[i] * auxiliaryControlCalculation;
  }

  valueCalcullation2 = (valueCalcullation2 * 10) % 11;

  if (valueCalcullation2 == 10) {
    valueCalcullation2 = 0;
  }

  if (valueCalcullation2 != cpf[10]) {
    return false;
  }
  return true;
};

export const checkCnpj = (cnpj) => {
  cnpj = cnpj.trim();
  cnpj = cnpj.replace(/\./g, "");
  cnpj = cnpj.replace("-", "");
  cnpj = cnpj.replace("/", "");
  cnpj = cnpj.split("");

  let valueCalcullation1 = 0;
  let valueCalcullation2 = 0;
  let controlVerifyDigit = false;
  let i = 0;
  let auxiliaryControlCalculation1 = 0;
  let auxiliaryControlCalculation2 = 0;

  for (i = 1; cnpj.length > i; i++) {
    if (cnpj[i - 1] != cnpj[i]) {
      controlVerifyDigit = true;
    }
  }

  if (controlVerifyDigit == false) {
    return false;
  }

  for (
    i = 0, auxiliaryControlCalculation1 = 5, auxiliaryControlCalculation2 = 13;
    cnpj.length - 2 > i;
    i++, auxiliaryControlCalculation1--, auxiliaryControlCalculation2--
  ) {
    if (auxiliaryControlCalculation1 >= 2) {
      valueCalcullation1 += cnpj[i] * auxiliaryControlCalculation1;
    } else {
      valueCalcullation1 += cnpj[i] * auxiliaryControlCalculation2;
    }
  }

  valueCalcullation1 = valueCalcullation1 % 11;

  if (valueCalcullation1 < 2) {
    valueCalcullation1 = 0;
  } else {
    valueCalcullation1 = 11 - valueCalcullation1;
  }

  if (valueCalcullation1 != cnpj[12]) {
    return false;
  }

  for (
    i = 0, auxiliaryControlCalculation1 = 6, auxiliaryControlCalculation2 = 14;
    cnpj.length - 1 > i;
    i++, auxiliaryControlCalculation1--, auxiliaryControlCalculation2--
  ) {
    if (auxiliaryControlCalculation1 >= 2) {
      valueCalcullation2 += cnpj[i] * auxiliaryControlCalculation1;
    } else {
      valueCalcullation2 += cnpj[i] * auxiliaryControlCalculation2;
    }
  }

  valueCalcullation2 = valueCalcullation2 % 11;

  if (valueCalcullation2 < 2) {
    valueCalcullation2 = 0;
  } else {
    valueCalcullation2 = 11 - valueCalcullation2;
  }

  if (valueCalcullation2 != cnpj[13]) {
    return false;
  } else {
    return true;
  }
};


export const isValidDate = (dateString, invert = false) => {
  if (invert) {
    dateString = dateString.substring(6,10) + '/' + dateString.substring(3,5) + '/' +dateString.substring(0,2);
  }
  let date = new Date(dateString);
  return !isNaN(date.getTime());
}
