import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;
  border-radius: 4px 4px 4px 4px;
  border: 0.5px 0.5px 0.5px 0.5px;
  opacity: 0px;
  background-color: ${({ theme, color }) => theme.color[color]};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "160px"};
  gap:${({ gap }) => gap || "16px"};
  padding:  ${({ padding }) => padding || "8px 8px 8px 8px"};
  border: 1px solid ${({ theme }) => theme.color.LightGray};
`;

export const Text = styled.text`
  color: ${({ theme, color }) => theme.color[color]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  gap:${({ gap }) => gap || "32px"};
  opacity: 0px;
  margin: 0px;
  padding: ${({ padding }) => padding || "8px 8px 8px 8px"};
`;

export const Image = styled.img`
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  border-radius: 4px 0px 0px 0px;
`;

export const SaibaMais = styled.div`
  cursor: pointer
 `;

