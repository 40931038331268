import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: ${({ height }) => height || "200px"};
  gap: "10px"; /* ${({ gap }) => gap || "20px"}; */
  padding: ${({ padding }) => padding || "16px 16px 16px 16px"};
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  gap: ${({ gap }) => gap || "10px"};
  padding: ${({ padding }) => padding || "16px 16px 16px 16px"};
`;


export const Text = styled.text`
  color: ${({ theme }) => theme.color.dark};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
  padding: ${({ padding }) => padding || "0 0 20px 0"};
`;

export const Responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 0,
    },
    items: 3,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 40,
  },
};

export const ContainerItem = styled.div`

  width: ${({ width }) => width || "248px"};;
  height: ${({ height }) => height || "100px"};
  // gap: 12px;
  border-radius: 4px 4px 4px 4px;
  border: 0.1rem solid ${({ theme }) => theme.color.neutralGray};
  padding: 0px;
  margin: 0px;
`;

export const Image = styled.img`
  width: ${({ width }) => width || "20px"};;
  height: ${({ height }) => height || "20px"};
  margin-top: -22px;
  margin-left: 10px;
`;

export const ContainerItemIcon = styled.div`
  position: relative;
  display: inline;
  top: -0.1em;
  margin-left: 1em;
  background-color: white;
  width: ${({ width }) => width || "248px"};
  height: ${({ height }) => height || "100px"};
`;

export const ContainerCarousel = styled.div`
  position: relative;
  padding: 2rem 0rem 2rem 0rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin: 0 0 0 0;

  .react-multi-carousel-list {
    position: unset;
    height: ${({ height }) => height || "100px"};
    padding: 0px;
    margin: 0px;
  }

  .react-multi-carousel-track   {
    width: ${({ width }) => width};
    gap: ${({ gap }) => gap || "20px"};
  }

  .dot-list-class {
    margin-top: 2rem;
    .react-multi-carousel-dot {
      button {
        width: 8px;
        height: 8px;
        border-radius: 1px;
        border: 0.01rem solid ${({ theme }) => theme.color.neutralGray};
        background: ${({ theme }) => theme.color.white};
        margin-left: 0.2rem;
      }
      &--active {
        button {
          background: ${({ theme }) => theme.color.dark};
        }
      }
    }
  }
  /* @media (${({ theme }) => theme.breakpoints.minXL}) {
		display: none;
	} */
`;


