import initialState from "../initialState";
import types from "../types";

const menuReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_MENU:
      return {
        ...state,
        menu: payload,
      };
    default:
      return state;
  }
};
export default menuReducer;
