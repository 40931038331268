import styled from "styled-components";

export const Spinner = styled.div`
  border: ${({ border }) => border} solid ${({ theme, color }) => theme.color[color]};
  border-top: ${({ border }) => border}  ${({ theme, colorBorderTop }) => theme.color[colorBorderTop]} solid;
  border-radius: 50%;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  animation: spin 2s linear infinite;
  padding: 0px;
  margin: ${({ margin }) => margin};
  align-items: center;
  /*
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
