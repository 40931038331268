import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShortText from "../../questions/ShortText";
import LongText from "../../questions/LongText";
import CheckBox from "../../questions/CheckBox";
import {
  setHasError,
  setQuestionnaire,
  sendOption,
  setQuestionDraw,
} from "../../../redux/quiz/actions";
import FinalQuestionnaire from "../finalQuestionnaire";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import {
  clearQuestionnaire,
  setNextQuestionId,
} from "../../../redux/quiz/actions";

const BodyQuestionnaire = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobileScreen();
  const dispatch = useDispatch();
  const { step, hasError, questionnaire, nextQuestionId, pivot } = useSelector(
    (state) => state.quiz,
  );
  // const [numberQuestions, setNumberQuestions] = useState(0);
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    createQuestions();
    dispatch(setNextQuestionId(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    createQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, questionnaire]);

  const goHome = () => {
    dispatch(clearQuestionnaire());
    navigate("/home");
  };

  const createQuestions = async () => {
    if (!questionnaire) {
      goHome();
      return;
    }

    const questionsStep = await questionnaire.questions.filter(
      (item) => item.question_step.ordering === parseInt(step),
    );
    if (!questionsStep) return;

    const questionOrder = await questionsStep.sort((q1, q2) =>
      parseInt(q1.id) < parseInt(q2.id)
        ? 1
        : parseInt(q1.id) > parseInt(q2.id)
          ? 2
          : 0,
    );

    const questionToShow = await searchQuestion(questionOrder);

    await setQuestions(
      questionToShow.sort((q1, q2) =>
        parseInt(q1.ordering) < parseInt(q2.ordering)
          ? 1
          : parseInt(q1.ordering) > parseInt(q2.ordering)
            ? 2
            : 0,
      ),
    );
    await dispatch(setQuestionDraw(questionToShow));
    await initializeError(questionToShow.length);
  };

  const initializeError = (length) => {
    let errors = new Array(length);
    for (let i = 0; i < length; i++) {
      errors[i] = false;
    }
    dispatch(setHasError(errors));
  };

  const setError = (isError, key) => {
    let errors = JSON.parse(JSON.stringify(hasError));
    errors[key] = isError;
    dispatch(setHasError(errors));
  };

  const setValueQuestion = (id, value) => {
    let questionnaireValue = convertQuestionnaire();
    for (let i = 0; i < questionnaireValue.questions.length; i++) {
      if (parseInt(questionnaireValue.questions[i].id) === parseInt(id)) {
        questionnaireValue.questions[i].value = value;
        dispatch(setQuestionnaire(questionnaireValue));
        return;
      }
    }
  };

  const setOptionQuestion = async (
    idQuestion,
    idOption,
    value,
    isOnlyChoise,
    valueText
  ) => {
    let questionnaireValue = convertQuestionnaire();
    let mustSendOption = false;
    for (let i = 0; i < questionnaireValue.questions.length; i++) {
      if (
        parseInt(questionnaireValue.questions[i].id) === parseInt(idQuestion)
      ) {
        for (
          let j = 0;
          j < questionnaireValue.questions[i].options.length;
          j++
        ) {
          let check = questionnaireValue.questions[i].options[j].value;
          if (isOnlyChoise === true) {
            check = false;
          }
          questionnaireValue.questions[i].options[j].value = check;
          if (questionnaireValue.questions[i].options[j].id === idOption) {
            if (questionnaireValue.questions[i].options[j].next_question_id) {
              dispatch(
                setNextQuestionId(
                  questionnaireValue.questions[i].options[j].next_question_id,
                ),
              );
            }
            questionnaireValue.questions[i].options[j].value = value;
            questionnaireValue.questions[i].options[j].valueText = valueText;
            if (value === true) {
              mustSendOption = true;
            }
          }
        }
        dispatch(setQuestionnaire(questionnaireValue));
      }
    }
    if (mustSendOption === true) {
      updateOption(idOption);
    }
  };

  const updateOption = (idOption) => {
    const parameter = {
      option_id: idOption,
      slug: questionnaire.slug,
    };
    dispatch(sendOption(parameter));
  };

  const convertQuestionnaire = () => {
    return JSON.parse(JSON.stringify(questionnaire));
  };

  const searchQuestion = (questionsStep) => {
    let anNextQuestionId = nextQuestionId === null ? "" : nextQuestionId;
    let questionToShow = [];
    for (let i = 0; i < questionsStep.length; i++) {
      if (questionsStep[i].is_active === 1) {
        const addQuestion = checkQuestionPivot(questionsStep[i]);
        if (addQuestion) {
          if (
            anNextQuestionId === "" ||
            anNextQuestionId === null ||
            questionsStep[i].id === anNextQuestionId ||
            questionsStep[i].next_question_id === null
          ) {
            questionToShow.push(questionsStep[i]);
            anNextQuestionId = questionsStep[i].next_question_id;
          }
        }
      }
    }
    return questionToShow;
  };

  const checkQuestionPivot = (questionCheck) => {
    if (pivot === null || parseInt(questionCheck.is_only_choice) === 0)
      return true;

    const options = questionCheck.options.filter(
      (x) => x.automatic_option_product_id === pivot.product_id,
    );
    if (options.length === 0) return true;
    updateOption(options[0].id);

    return false;
  };

  const getQuestion = (item, key) => {
    if (item.is_active.toString() !== "1") {
      return;
    }

    if (item.is_short_textual.toString() === "1") {
      return (
        <ShortText
          idQuestion={item.id}
          title={item.question}
          index={key}
          value={item.value === undefined ? "" : item.value}
          validation={item.validation}
          setError={setError}
          isOptional={item.is_optional}
          setValueQuestion={setValueQuestion}
        ></ShortText>
      );
    }

    if (item.is_long_textual.toString() === "1") {
      return (
        <LongText
          idQuestion={item.id}
          title={item.question}
          index={key}
          value={item.value === undefined ? "" : item.value}
          validation={item.validation}
          setError={setError}
          isOptional={item.is_optional}
          setValueQuestion={setValueQuestion}
        ></LongText>
      );
    }

    if (
      item.is_only_choice.toString() === "1" ||
      item.is_multiple_choice.toString() === "1"
    ) {
      return (
        <CheckBox
          idQuestion={item.id}
          title={item.question}
          isOnlyChoise={item.is_only_choice.toString() === "1" ? true : false}
          index={key}
          value={item.value}
          options={item.options}
          setValueQuestion={setOptionQuestion}
        ></CheckBox>
      );
    }
  };

  if (step === -1) {
    return <FinalQuestionnaire></FinalQuestionnaire>;
  }

  // if (step === -1) {
  //   goFinish();
  //   return;
  // }

  return (
    <S.Container justifyContent="start" minHeight={isMobile ? "auto" : "auto"}>
      {questions &&
        questions
          .filter((x) => x.is_active === 1)
          .map((item, key) => {
            return getQuestion(item, key);
          })}
    </S.Container>
  );
};

export default BodyQuestionnaire;
