// import { applyMiddleware, createStore, compose } from 'redux';
// import { thunk } from 'redux-thunk';
import reducers from '../reducers';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// export default createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

import { configureStore } from '@reduxjs/toolkit'
// import { myCustomApiService } from './api'

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
      }
    })
})

export default store;
