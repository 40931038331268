import React from "react";
import { createTheme } from "@material-ui/core";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import typography from "./foundations/typography.js";
import { color, shadow, zIndex } from "./foundations/styles.js";
import breakpoints, { breakpointSizes } from "./foundations/mediaQueries.js";
import GlobalStyle from "./foundations/global.js";

export { GlobalStyle };

const MuiTheme = createTheme({
  typography: {
    fontFamily: "unset",
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 10,
  },
  palette: {
    primary: {
      light: color.primary,
      main: color.primary,
      dark: color.primary2,
    },
  },
  breakpoints: {
    values: {
      xs: breakpointSizes.XXS,
      sm: 700,
      md: breakpointSizes.XS,
      lg: breakpointSizes.LG,
      xl: breakpointSizes.XL,
    },
  },
});

const theme = {
  typography,
  color,
  shadow,
  breakpoints,
  zIndex,
};

export const CustomThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default theme;
