import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStep,
  sendQuestionnaire,
  setNextQuestionId,
} from "../../../redux/quiz/actions";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import * as S from "./styles";
import { createEventDataLayer } from "../../../services/dataLayers";
import ProgressIndicator from "../../progressIndicator";

const CommandsQuestionnaire = ({ stepInitial }) => {
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);
  const isMobile = useCheckIsMobileScreen();
  const dispatch = useDispatch();
  const { step, numberSteps, hasError, questionnaire, questionsDraw } =
    useSelector((state) => state.quiz);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const checkForm = () => {
    let messageErro = "";
    if (!questionsDraw) {
      setMessageError("Favor preencher os campos do formulário");
      return false;
    }
    if (questionsDraw) {
      for (let i = 0; i < questionsDraw.length; i++) {
        if (questionsDraw[i].is_optional === 0) {
          if (
            questionsDraw[i].is_short_textual ||
            questionsDraw[i].is_long_textual
          ) {
            messageErro = checkTextualField(questionsDraw[i], messageErro);
          }
          if (
            questionsDraw[i].is_only_choice ||
            questionsDraw[i].is_multiple_choice
          ) {
            messageErro = checkOptionsField(questionsDraw[i], messageErro);
          }
        }
      }
    }
    setMessageError(messageErro);
    return messageErro === "" ? true : false;
  };

  const checkTextualField = (question, messageErro) => {
    if (question.value === undefined) {
      return "Favor preencher os campos do formulário";
    }
    if (question.value === "") {
      return "Favor preencher os campos do formulário";
    }
    return messageErro;
  };

  const checkOptionsField = (question, messageErro) => {
    let hasError = true;
    for (let i = 0; i < question.options.length; i++) {
      if (question.options[i].value) {
        hasError = false;
      }
    }
    return hasError ? "Favor preencher os campos do formulário" : messageErro;
  };

  const onClickBack = async () => {
    setLoading(true);
    await dispatch(setNextQuestionId(null));
    await dispatch(sendQuestionnaire(questionnaire));
    await dispatch(setStep(parseInt(step) - 1));
  };
  const onClickContinue = () => {
    if (hasError.find((x) => x === true)) {
      return;
    }
    if (!checkForm()) {
      return;
    }
    setLoading(true);
    createEventDataLayer(questionnaire.name, "<Etapa " + step + ">");
    dispatch(sendQuestionnaire(questionnaire));
    dispatch(setStep(parseInt(step) + 1));
  };
  const onClickEnviar = () => {
    if (!checkForm()) {
      return;
    }
    setLoading(true);
    createEventDataLayer(questionnaire.name, "<Concluido>");
    dispatch(sendQuestionnaire(questionnaire));
    dispatch(setStep(-1));
  };
  if (step === -1) return;
  return (
    <S.Container
      justifyContent={step <= 1 ? "flex-end" : "space-between"}
      padding={isMobile ? "8px 18px 35px 16px" : "8px 16px 8px 16px"}
      gap={isMobile ? "2px" : "8px"}
      buttons={
        isMobile
          ? "position: relative;left: 50%;transform: translateX(-50%);bottom: 0px;"
          : ""
      }
    >
      <S.Text fontColor="red" fontName="bodyText2">
        {messageError}
      </S.Text>
      {step > stepInitial && (
        <S.Button
          backgroudColor="dark"
          fontColor="beige"
          borderColor="beige"
          height={isMobile ? "35px" : "48px"}
          onClick={() => onClickBack()}
        >
          <S.Text fontName="button3" fontColor="beige">
            Voltar
          </S.Text>
        </S.Button>
      )}
      {step < numberSteps && (
        <S.Button
          backgroudColor="beige"
          fontColor="dark"
          borderColor="dark"
          height={isMobile ? "35px" : "48px"}
          onClick={() => onClickContinue()}
        >
          <S.ContainerTextLoad>
            {loading && (
              <ProgressIndicator
                width={"24px"}
                height={"24px"}
                color={"white"}
                border={"4px"}
                colorBorderTop={"beige"}
              ></ProgressIndicator>
            )}
            <S.Text fontName="button3" fontColor="white">
              Continuar
            </S.Text>
          </S.ContainerTextLoad>
        </S.Button>
      )}
      {step === numberSteps && (
        <S.Button
          backgroudColor="beige"
          fontColor="dark"
          borderColor="dark"
          height={isMobile ? "35px" : "48px"}
          onClick={() => onClickEnviar()}
        >
          <S.Text fontName="button3" fontColor="white">
            Enviar
          </S.Text>
        </S.Button>
      )}
    </S.Container>
  );
};

export default CommandsQuestionnaire;
