import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasError,
  setQuestionnaire,
  sendQuestionnaire
} from "../../../redux/quiz/actions";
import ShortText from "../../questions/ShortText";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import { createEventDataLayer } from "../../../services/dataLayers";

const FormProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useCheckIsMobileScreen();
  const { questionnaire, hasError } = useSelector((state) => state.quiz);
  const [messageError, setMessageError] = useState("");

  useEffect(() => {
    initializeError(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeError = async (length) => {
    let errors = new Array(length);
    for (let i = 0; i < length; i++) {
      errors[i] = false;
    }
    await dispatch(setHasError(errors));
  };

  const setError = (isError, key) => {
    let errors = JSON.parse(JSON.stringify(hasError));
    errors[key] = isError;
    dispatch(setHasError(errors));
  };

  const convertQuestionnaire = () => {
    return JSON.parse(JSON.stringify(questionnaire));
  };

  const setValueQuestion = (id, value) => {
    let questionnaireValue = convertQuestionnaire();
    for (let i = 0; i < questionnaireValue.questions.length; i++) {
      if (parseInt(questionnaireValue.questions[i].id) === parseInt(id)) {
        questionnaireValue.questions[i].value = value;
        dispatch(setQuestionnaire(questionnaireValue));
        return;
      }
    }
  };

  const getQuestion = (item, key) => {
    if (item.is_active.toString() !== "1") {
      return;
    }

    if (item.is_short_textual.toString() === "1") {
      return (
        <ShortText
          idQuestion={item.id}
          title={item.question}
          index={key}
          value={item.value}
          validation={item.validation}
          setError={setError}
          isOptional={item.is_optional}
          setValueQuestion={setValueQuestion}
          backgroundColor="white"
          inputColor="darkGray"
          textColor="darkGray"
        ></ShortText>
      );
    }
  };

  const checkForm = () => {
    const questions = questionnaire.questions
          .filter((x) => x.is_active === 1 && x.question_step_id === 1);
    let messageErro = "";
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].value === undefined) {
        messageErro = "Favor preencher os campos do formulário";
      } else {
        if (questions[i].value === "") {
          messageErro = "Favor preencher os campos do formulário";
        }
      }

    }
    setMessageError(messageErro);
    return messageErro === "" ? true : false;
  };

  const onClickEnviar = () => {
    if (checkForm()) {
      createEventDataLayer(questionnaire.name)
      dispatch(sendQuestionnaire(questionnaire));
      navigate("/question", {
        state: {
          questionnaire: questionnaire,
          stepInitial: 2,
        },
      });
    }
  };

  if (!questionnaire) return;

  return (
    <S.Container
      width={isMobile ? "100%" : "528px"}
      height={"auto"}
      padding={isMobile ? "10px" : "10px"}
      gap="0px"
    >
      {questionnaire.questions &&
        questionnaire.questions
          .filter((x) => x.is_active === 1 && x.question_step.ordering === 1)
          .map((item, key) => {
            return getQuestion(item, key);
          })}
      <S.ContainerButton>
        <S.Text fontColor="red" fontName="bodyText2">
          {messageError}
        </S.Text>
        <S.Button
          backgroudColor="beige"
          fontColor="dark"
          borderColor="dark"
          height={isMobile ? "35px" : "48px"}
          onClick={() => onClickEnviar()}
        >
          <S.Text fontName="button3" fontColor="white">
            Enviar
          </S.Text>
        </S.Button>
      </S.ContainerButton>
    </S.Container>
  );
};

export default FormProduct;
