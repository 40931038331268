import { ZIndices } from 'styled-components';

const colors = {
  // Palette
  LightGray: '#D9D9D9',
  gray: '#828282',
  LightGray2: '#AAAAAA',
  darkGray: '#4b413e',
  mediumGray: '#626262',
  white: '#FFFFFF',
  black: '#000000',
  yellow: '#FFC748',
  dark: '#111B25',
  neutralGray: '#464646',
  beige:'#C3A474',
  dark90: '#262C3A',
  red: '#FF0000'
};

const semanticColors = {
  primary: colors.dark,
};

export const color = {
  ...semanticColors,
  ...colors,
};

export const zIndex: ZIndices = {
  overBackground: 1,
  tooltip: 20,
  pageHeader: 40,
  modal: 50,
  drawer: 60,
};

export const shadow = {
  superior: '0.5rem 0.5rem 2rem 0 rgba(37, 37, 39, .25)',
  intermediary: '0 0px 2.4333rem 0 rgba(166, 171, 189, .25)',
};
