import initialState from "../initialState";
import types from "../types";

const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PRODUCT:
      return {
        ...state,
        productData: payload,
      };
    default:
      return state;
  }
};
export default productReducer;
