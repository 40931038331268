/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./styles";
import Container from "../../components/home/container";
import Header from "../../components/home/header";
import BannerProduct from "../../components/product/bannerProduct";
import Footer from "../../components/home/footer";
import useCheckIsMobileScreen from "../../hooks/useCheckIsMobileScreen";
import { getProduct } from "../../redux/product/actions";
import { getQuestionnaire, setPivot } from "../../redux/quiz/actions";
import {
  exportHTMLFGTS,
  exportHTMLRecebiveis,
  exportHTMLConsorcio,
  exportHTMLSalario,
  exportHTMLCaptialGiro,
  exportHTMLConsignado,
  exportHTMLVeiculo,
  exportHTMLImobiliaria,
  exportHTMLRepresentante,
} from "../../utils/productHtml/productHTML.jsx";

const Product = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useCheckIsMobileScreen();
  const [width, setWidth] = useState("1920px");
  const [height, setHeight] = useState("664px");
  const [gap, setGap] = useState("16px");

  const consignado = "/images/banner-credito-consignado.jpeg";
  const consorcio = "/images/banner-venda-de-consorcio.jpeg";
  const recebiveis = "/images/banner-antecipacao-de-recebiveis.jpeg";
  const capitalGiro = "/images/banner-capital-de-giro.jpeg";
  const garantiaImobiliaria = "/images/banner-emprestimo-com-garantia-imobiliaria.jpeg";
  const garantiaVeiculo = "/images/banner-emprestimo-com-garantia-de-veiculos.jpeg";
  const salario = "/images/banner-antecipacao-de-salario.jpeg";
  const salesperson = "/images/banner-representante.png";
  const fgts = "/images/banner-antecipacao-de-fgts.jpeg";

  const [urlImage, setUrlImage] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [quizzes, setQuizzes] = useState("");

  const { productData } = useSelector((state) => state.product);

  useEffect(() => {
    getProductData();
  }, [location]);

  const getProductData = async () => {
    await dispatch(getProduct(setProduct()));
    await dispatch(setPivot(location.state?.pivot ? location.state?.pivot : null));
  };

  const getQuestionnaireQuiz = async (quizzes) => {
      await dispatch(getQuestionnaire(quizzes));
  }

  useEffect(() => {
    if (productData) {
      setTitle(productData.name);
      setQuizzes(productData.quiz.slug);
      setSubTitle(productData.description);
      getQuestionnaireQuiz(productData.quiz.slug);
      if (isMobile) {
        setWidth("100%");
        setHeight("100%");
        setGap("0px");
        setUrlImage(getImage(productData.slug));
      } else {
        setWidth("100%");
        setHeight("664px");
        setGap("16px");
        setUrlImage(getImage(productData.slug));
      }
    }
  }, [productData]);

  const setProduct = () => {
    switch (location.state?.product) {
      case "credito-consignado":
        return "consignado-publico";
      case "antecipacao-de-salario":
        return "consignado-privado";
      case "venda-seu-consorcio":
        return "compra-de-consorcios";
      default:
        return location.state?.product;
    }
  };

  const getImage = (item) => {
    switch (item) {
      case "antecipacao-de-fgts":
        return fgts;
      case "compra-de-consorcios":
        return consorcio;
      case "consignado-privado":
        return salario;
      case "consignado-publico":
        return consignado;
      case "antecipacao-de-recebiveis":
        return recebiveis;
      case "capital-de-giro":
        return capitalGiro;
      case "emprestimo-com-garantia-imobiliaria":
        return garantiaImobiliaria;
      case "emprestimo-com-garantia-de-veiculos":
        return garantiaVeiculo;
      case "quero-ser-representante":
        return salesperson;
      default:
        return fgts;
    }
  };

  const getHTML = (item) => {
    switch (item) {
      case "antecipacao-de-fgts":
        return exportHTMLFGTS();
      case "compra-de-consorcios":
        return exportHTMLConsorcio();
      case "consignado-privado":
        return exportHTMLSalario();
      case "consignado-publico":
        return exportHTMLConsignado();
      case "antecipacao-de-recebiveis":
        return exportHTMLRecebiveis();
      case "capital-de-giro":
        return exportHTMLCaptialGiro();
      case "emprestimo-com-garantia-imobiliaria":
        return exportHTMLImobiliaria();
      case "emprestimo-com-garantia-de-veiculos":
        return exportHTMLVeiculo();
        case "quero-ser-representante":
      return exportHTMLRepresentante();
      default:
        return "";
    }
  };

  const product = location.state?.product;

  if (product === undefined || !product) {
    window.location.href = process.env.REACT_APP_FRONT;
    return;
  }

  window.scrollTo(0, 0);

  return (
    <S.BaseContainer gap={gap}>
      <Container
        width={width}
        height={height}
        padding="0px"
        gap="0px"
        opacity="0px"
        colorcontainer="white"
      >
        <Header showClose={false}></Header>
        <BannerProduct
          urlImage={urlImage}
          quizzes={quizzes}
          title={title}
          subTitle={subTitle}
        ></BannerProduct>
        <S.Wrapper
          padding={isMobile ? "40px 20px 40px 20px" : "0px 128px 128px 128px"}
          gap={isMobile ? "16px" : "32px"}
          paddingP={isMobile ? "16px 16px 16px 16px" : "36px 36px 16px 36px"}
        >
          <S.Text
            color="darkGray"
            fontName={isMobile ? "mobileBody" : "titleDesktop2"}
          >
            {parse(getHTML(productData ? productData.slug : ""))}
          </S.Text>
        </S.Wrapper>
        <Footer></Footer>
      </Container>
    </S.BaseContainer>
  );
};

export default Product;
