import initialState from "../initialState";
import types from "../types";

const questionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case types.GET_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    case types.SET_STEP:
      return {
        ...state,
        step: payload,
      };
    case types.SET_NUMBERSTEP:
      return {
        ...state,
        numberSteps: payload,
      };
    case types.SET_HASERROR:
      return {
        ...state,
        hasError: payload,
      };
    case types.SET_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: payload,
      };
    case types.SET_OPTION:
      return {
        ...state
      };
    case types.SET_CLEAR_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: null,
        nextQuestionId: null
      };
    case types.SET_NEXTQUESTIONID:
      return {
        ...state,
        nextQuestionId: payload
      }
    case types.SET_PIVOT:
      return {
        ...state,
        pivot: payload
      }
    case types.SET_QUESTIONSDRAW:
      return {
        ...state,
        questionsDraw: payload
      }

    default:
      return state;
  }
};
export default questionReducer;
