import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding:  ${({ padding }) => padding || "16px 16px 16px 16px"};
`;

export const Text = styled.text`
  color: ${({ theme }) => theme.color.dark};
  font: ${({ theme }) => theme.typography.mobileHeader2};
  text-align: left;
`;

export const Responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 60,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 85,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 40,
  },
};

export const ContainerCarousel = styled.div`
  position: relative;
  padding: 2rem 0rem 2rem 0rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin: 0 0 0 0;

  .react-multi-carousel-list {
    position: unset;
    height: ${({ height }) => height || "100px"};
    padding: 0px;
    margin: 0px;
  }

  .react-multi-carousel-track   {
    width: ${({ width }) => width};
    gap: ${({ gap }) => gap || "20px"};
  }

  .dot-list-class {
    margin-top: 2rem;
    .react-multi-carousel-dot {
      button {
        width: 8px;
        height: 8px;
        border-radius: 1px;
        border: 0.01rem solid ${({ theme }) => theme.color.neutralGray};
        background: ${({ theme }) => theme.color.white};
        margin-left: 0.2rem;
      }
      &--active {
        button {
          background: ${({ theme }) => theme.color.dark};
        }
      }
    }
  }
  /* @media (${({ theme }) => theme.breakpoints.minXL}) {
		display: none;
	} */
`;


