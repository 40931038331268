import styled from 'styled-components';

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: Hug (3,450.75px)px;
  padding: 0;
  margin: 0;
  gap: 104px;
  opacity: 0px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  padding:  ${({ padding }) => padding || "16px 16px 16px 16px"};
  gap:  ${({ gap }) => gap || "16px"};
`;


export const Text = styled.text`
  color: ${({ theme, color }) => theme.color[color]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;
