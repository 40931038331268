import getCookies from "../cookies";

const event = "select_content";

export const createEventHomeDataLayer = async (page = "home") => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    pageCategory: page,
    userId: await getCookies(),
  });
};

export const createEventDataLayer = async (contentType, contentId = "") => {
  window.dataLayer.push({
    ...(event !== "") && {event: event},
    ...(contentType !== "") && {content_type: contentType},
    ...(contentId !== "") && {content_id: contentId},
  });
};
