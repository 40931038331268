export const formatPhone = (phone) => {
  if (phone) {
    let r = phone.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }
};

export const formatDate = value => {
  let v = value.replace(/\D/g,'').slice(0, 10);
  if (v.length >= 5) {
    return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
  }
  else if (v.length >= 3) {
    return `${v.slice(0,2)}/${v.slice(2)}`;
  }
  return v
}

export const formatDocumentCnpjCpf = (value, isCPF = false) => {
  if (value) {
    const cnpjCpf = value.replace(/\D/g, "");
    if (cnpjCpf.length === 11 && isCPF) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    }
    return cnpjCpf.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5",
    );
  }
};

export const formatCurrency = (
  value,
  formatNumberZero = false,
  formatNumberTwoZero = false,
  replaceDecimal = true,
) => {
  if (!value) return "";
  if (!value && !formatNumberZero) return "";
  if (value === 0 && formatNumberTwoZero) return "R$ 00,00";
  value = value.trim();
  value = value.replace("R$", "");
  value = value.replace(/\./g, "");
  if (replaceDecimal) value = value.replace(",00", "");
  if (value.trim().length > 0) {
    const config = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    };
    return parseFloat(value.toString()).toLocaleString("pt-BR", config);
  }
  return "";
};
