import React from "react";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";

const CustomersSayItem = ({ body, name, client }) => {
  const isMobile = useCheckIsMobileScreen();
  const icon = "/images/customersSay.svg";

  const getMobile = () => {
    return (
      <S.ContainerItemIcon>
        <S.ContainerItem width="280px" height="186px">
          <S.Image src={icon} width="20px" height="20px"></S.Image>
          <S.Wrapper padding="16px 8px 4px 8px" gap="8px">
            <S.Wrapper padding="0px 0px 0px 0px" height="90px">
              <S.Text color="white" fontName="mobileBody">
                {body}
              </S.Text>
            </S.Wrapper>
            <S.Line></S.Line>
            <S.Wrapper padding="0px 0px 0px 0px" gap="2px">
              <S.Text color="white" fontName="bodyText2">
                {name}
              </S.Text>
              <S.Text color="white" fontName="mobileBody2">
                {client}
              </S.Text>
            </S.Wrapper>
          </S.Wrapper>
        </S.ContainerItem>
      </S.ContainerItemIcon>
    );
  };
  const getDesktop = () => {
    return (
      <S.ContainerItemIcon>
        <S.ContainerItem height="304px" width="390px">
          <S.Image src={icon} width="40px" height="40px"></S.Image>
          <S.Wrapper padding="32px 16px 16px 16px">
            <S.Wrapper padding="0px 0px 0px 0px" height="152px">
              <S.Text color="white" fontName="bodyText1">
                {body}
              </S.Text>
            </S.Wrapper>
            <S.Wrapper padding="0px 0px 0px 0px" gap="8px">
              <S.Line></S.Line>
              <S.Wrapper padding="0px 0px 0px 0px" gap="4px" height="40px">
                <S.Text color="white" fontName="bodyText2Bold">
                  {name}
                </S.Text>
                <S.Text color="white" fontName="bodyText2">
                  {client}
                </S.Text>
              </S.Wrapper>
            </S.Wrapper>
          </S.Wrapper>
        </S.ContainerItem>
      </S.ContainerItemIcon>
    );
  };
  return isMobile ? getMobile() : getDesktop();
};

export default CustomersSayItem;
