import styled from 'styled-components';
import img from "../../img/questionnaire.jpeg";

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: ${({ width }) => width || "100%"};
  height: auto;
  padding: 0;
  margin: 0;
  opacity: 0px;
  padding: ${({ padding }) => padding || "0px 0px 16px 0px"};
  background: ${({ theme }) => theme.color.dark};
`;

export const BaseContainerDeskTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;
  height: 100%;
  min-height: 900px;
  padding: 0;
  margin: 0;
  opacity: 0px;
  padding-bottom:0px;
  border-top: 2px solid #3f4142;
  border-bottom: 2px solid #3f4142;
  background: ${({ theme }) => theme.color.dark};
`;

export const ContainerImage = styled.div`
  width: ${({ width }) => width || "100%"};
  height: 900px;
  padding: 0;
  margin: 0;
  opacity: 0px;
  background: ${({ theme }) => theme.color.dark};
  background-image: url(${img});
  background-size: cover;
  background-position: center;
`;

export const BaseContainerImage = styled.div`
  display: flex;
  flex-direction: row;
 // justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: ${({ width }) => width || "100%"};
`;
