import initialState from "../initialState";
import types from "../types";

const ipReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_IP:
      return {
        ...state,
        ip: payload,
      };
    default:
      return state;
  }
};
export default ipReducer;
