import styled from "styled-components";

export const Container = styled.div`
  width: ${(props) => props.width};
  height: "${(props) => props.height}";
  padding: ${(props) => props.padding};
  gap: ${(props) => props.gap};
  opacity: ${(props) => props.opacity};
  background: ${({ theme, colorcontainer }) => theme.color[colorcontainer]};
`;
