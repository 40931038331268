import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

const ProgressIndicator = ({
  width,
  height,
  color,
  border,
  colorBorderTop,
  margin,
}) => {
  return (
    <S.Spinner
      color={color}
      height={height}
      width={width}
      border={border}
      colorBorderTop={colorBorderTop}
      margin={margin}
    ></S.Spinner>
  );
};

ProgressIndicator.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  colorBorderTop: PropTypes.string,
  margin: PropTypes.string,
};

export default ProgressIndicator;
