export const menu = () => {
  return [
      {
        id: 1,
        description: "Para você",
        path: "home",
        height: 500,
        isMobileDesktop: "A",
        subMenu: [
          {
            id: 11,
            description: "Antecipação de FGTS",
            path: "/question",
            param: 1
          },
          {
            id: 12,
            description: "Compra de Consórcios",
            path: "/question",
            param: 2
          },
          {
            id: 13,
            description: "Consignado Privado",
            path: "/question",
            param: 3
          },
          {
            id: 14,
            description: "Crédito Consignado Público Estadual e Federal",
            path: "/question",
            param: "4"
          },
        ]
      },
      {
        id: 2,
        description: "Para empresas",
        path: "home",
        height: 600,
        isMobileDesktop: "A",
        subMenu: [
          {
            id: 21,
            description: "Antecipação de recebíveis",
            path: "home",
          },
          {
            id: 22,
            description: "Capital de Giro",
            path: "home",
          },
          {
            id: 23,
            description: "Empréstimo com garantia imobiliária",
            path: "home",
          },
          {
            id: 24,
            description: "Empréstimo com garantia veículos",
            path: "home",
          },
        ]

      },
      {
        id: 4,
        description: "Quero ser representante",
        path: "home",
        height: 0,
        isMobileDesktop: "A",
        subMenu: []
      },
      {
        id: 5,
        description: "Fale conosco",
        path: "/question",
        param: "5",
        height: 0,
        isMobileDesktop: "M",
        subMenu: []
      }
  ]
}

export const menuMobile = () => {
  return {
    id: 99,
    name: "Fale Conosco",
    description: null,
    ordering: 1,
    is_active: 1,
    slug: "fale-conosco",
    created_at: "2024-10-10T06:00:00.000000Z",
    updated_at: null,
    products: [
    ],
  };
};


