import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomersSayItem from "../customersSayItem";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";

const CustomersSays = () => {
  const { homeData } = useSelector((state) => state.home);
  const [customers, setCustomers] = useState([]);
  const isMobile = useCheckIsMobileScreen();

  useEffect(() => {
    if (homeData) {
      const clientes = homeData.highlights.filter(
        (x) => x.type === "prova-social",
      );
      setCustomers(clientes);
    }
  }, [homeData]);

  if (customers.length === 0) return;

  const getMobile = () => {
    return (
      <S.Container padding="16px 16px 16px 16px">
        <S.Text>O que nossos cliente dizem sobre nós!</S.Text>
        <S.ContainerCarousel height={"auto"} width={"auto"} gap={"0"}>
          <Carousel
            responsive={S.Responsive}
            showDots={false}
            arrows={false}
            dotListClass="dot-list-class"
            partialVisible
            autoPlay
            autoPlaySpeed={7000}
            infinite
          >
            {customers.map((item, key) => {
              const title = item.title.split("\n");
              const name = title[0];
              const client = title.length > 1 ? title[1] : "";
              return (
                <CustomersSayItem
                  key={key}
                  name={name}
                  client={client}
                  body={item.description}
                ></CustomersSayItem>
              );
            })}
          </Carousel>
        </S.ContainerCarousel>
      </S.Container>
    );
  };
  const getDesktop = () => {
    return (
      <S.Container padding="64px 128px 0px 128px" height="350px">
        <S.Text>O que nossos cliente dizem sobre nós!</S.Text>
        <S.ContainerCarousel height={"auto"} width={"390px"} gap={"40px"}>
          <Carousel
            responsive={S.Responsive}
            showDots={false}
            arrows={false}
            dotListClass="dot-list-class"
            partialVisible
            autoPlay
            autoPlaySpeed={7000}
            infinite
          >
            {customers.map((item, key) => {
              const title = item.title.split("\n");
              const name = title[0];
              const client = title.length > 1 ? title[1] : "";
              return (
                <CustomersSayItem
                  key={key}
                  name={name}
                  client={client}
                  body={item.description}
                ></CustomersSayItem>
              );
            })}
          </Carousel>
        </S.ContainerCarousel>
      </S.Container>
    );
  };
  return isMobile ? getMobile() : getDesktop();
};
export default CustomersSays;
