import axios from 'axios';
import types from "../types";

export const getProduct = (param) => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_BFF}product?id=${param}`;
    try {
      axios
        .get(url, { timeout: 300000, withCredentials: false })
        .then((response) => {
          dispatch({
            type: types.GET_PRODUCT, payload: response.data.data.product
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
    }
  };
};

