import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.color.dark};
  gap:${({ gap }) => gap || "2px"};
  padding: ${({ padding }) => padding || "8px 0px 8px 8px"};

  ${({ buttons }) => buttons || ""};
`;

export const Button = styled.button`
  background: ${({ theme, backgroudColor }) => theme.color[backgroudColor]};
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  border: 1px solid
    ${({ theme, borderColor }) => theme.color[borderColor]};
  width: 100%;
  height: ${({ height }) => height || "35px"};
  border-radius: 8px 8px 8px 8px;
  padding: ${({ padding }) => padding || "0 0"};
`;

export const Text = styled.text`
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
  padding: ${({ padding }) => padding || "0 0"};
`;

export const ContainerTextLoad = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: "center";
  align-content: "center";
  gap: 8px;
  padding: 0px;
  margin: 0px;
`;
