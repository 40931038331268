/* eslint-disable import/no-anonymous-default-export */
export default {
  isOpen: false,
  step: 0,
  numberSteps: 0,
  hasError: null,
  questionnaire: null,
  nextQuestionId: null,
  pivot: null,
  questionsDraw: null
};
