import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;
  padding:  ${({ padding }) => padding || "32px 16px 16px 16px"};
  height: ${({ height }) => height || "816px"};
  gap:${({ gap }) => gap || "24px"};
`;

export const Text = styled.text`
  color: ${({ theme, color }) => theme.color[color]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "32px"};
  gap:${({ gap }) => gap || "32px"};
  opacity: 0px;
  padding: ${({ padding }) => padding || "0 0"};
  margin: ${({ margin }) => margin || "0 0"};
`;

export const Button = styled.button`
  background: ${({ theme, background }) => theme.color[background]};
  color: ${({ theme, color }) => theme.color[color]};
  border: 1px solid ${({ theme, borderColor }) => theme.color[borderColor]};;
  min-width: 170px;

  border-radius: 4px 4px 4px 4px;
  padding: ${({ padding }) => padding || "0 0"};
  cursor: pointer;
`;
