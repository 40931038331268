export const typographyBreakpoint1 = "@media(max-width: 767px)";
export const typographyBreakpoint2 = "@media(max-width: 1201px)";
const typography = {
  title1: {
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "21.09px",
    fontWeight: 600,
    fontStyle: "none",
    [typographyBreakpoint1]: {
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
    },
  },
  titleDesktop: {
    fontFamily: "Power Grotesk",
    fontSize: "66px",
    lineHeight: "67.2px",
    fontWeight: 400,
    fontStyle: "none",
    // [typographyBreakpoint1]: {
    //   fontSize: "1.8rem",
    //   lineHeight: "2.2rem",
    // },
    // [typographyBreakpoint2]: {
    //   fontSize: "2.8rem",
    //   lineHeight: "3.2rem",
    // },
  },
  titleDesktop2: {
    fontFamily: "Roboto",
    fontSize: "24px",
    lineHeight: "28.13px",
    fontWeight: 400,
    fontStyle: "none",
  },
  mobileHeader1: {
    fontFamily: "Power Grotesk",
    fontSize: "24px",
    lineHeight: "28.8px",
    fontWeight: 400,
    fontStyle: "none",
  },
  mobileHeader2: {
    fontFamily: "Power Grotesk",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "none",
  },
  mobileBody: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16.41px",
    fontWeight: 400,
    fontStyle: "none",
  },
  mobileBody2: {
    fontFamily: "Roboto",
    fontSize: "10px",
    lineHeight: "11.72px",
    fontWeight: 400,
    fontStyle: "none",
  },
  button: {
    fontFamily: "Power Grotesk",
    fontSize: "16px",
    lineHeight: "19.2px",
    fontWeight: 400,
    fontStyle: "none",
  },
  button1: {
    fontFamily: "Power Grotesk",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "none",
  },
  button2: {
    fontFamily: "Power Grotesk",
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: 400,
    letterSpacing: "0.5px",
    fontStyle: "none",
  },
  label: {
    fontFamily: "Power Grotesk",
    fontSize: "12px",
    lineHeight: "14.4px",
    fontWeight: 400,
    fontStyle: "none",
  },
  label2: {
    fontFamily: "Power Grotesk",
    fontSize: "18px",
    lineHeight: "21.6px",
    fontWeight: 400,
    fontStyle: "none",
  },
  buttonDeskTop: {
    fontFamily: "Power Grotesk",
    fontSize: "32px",
    lineHeight: "38.4px",
    fontWeight: 400,
    fontStyle: "none",
  },
  bodyText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16.4px",
    fontWeight: 400,
    fontStyle: "none",
  },
  bodyText1: {
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "23.44px",
    fontWeight: 400,
    fontStyle: "none",
  },
  bodyText1Bold: {
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "23.44px",
    fontWeight: 700,
    fontStyle: "none",
  },
  bodyText2Bold: {
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "21.09px",
    fontWeight: 700,
    fontStyle: "none",
  },
  bodyText2: {
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "14.06px",
    fontWeight: 400,
    fontStyle: "none",
  },
  bodyTextNegrito: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16.4px",
    fontWeight: 700,
    fontStyle: "none",
  },
  bodyTextNegrito2: {
    fontFamily: "Inter",
    fontSize: "12px",
    lineHeight: "14.52px",
    fontWeight: 600,
    fontStyle: "none",
  },
  button3: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "18.75px",
    fontStyle: "none",
  },
};


export default typography;
