import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

const CheckBox = ({
  idQuestion,
  title,
  isOnlyChoise,
  options,
  index,
  setValueQuestion,
}) => {
  const [messageError, setMessageError] = useState("");
  // const [optionsField, setOptionsField] = useState(options);
  const [valueField, setValueField] = useState();
  const [focusedIndex, setFocusedIndex] = useState(0);

  useEffect(() => {
    getOptions();
  }, [options]);

  const getOptions = () => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].is_textual && options.valueText !== undefined) {
        setValueField(options.valueTex);
      }
    }
  };

  const onChange = (value, id, isTextual) => {
    setMessageError("");
    setValueQuestion(idQuestion, id, value, isOnlyChoise, valueField);
    if (isTextual !== null && isTextual.toString() === "1") {
      setFocusedIndex(id);
    } else {
      setFocusedIndex(0);
    }
  };

  const handleChange = (value) => {
    setValueField(value);
  };
  const handleBlur = (value, id) => {
    if (value === undefined || value === "") {
      setMessageError("Informe o complemento para a opção outros");
      focus(id);
      return;
    }
    setMessageError("");
    setValueQuestion(idQuestion, id, value, isOnlyChoise, valueField);
  };

  const focus = (id) => {
    const textbox = document.getElementById(id);
    textbox.focus();
  };


  return (
    <S.Container key={index}>
      <S.Text color="white" fontName="bodyTextNegrito">
        {title}
      </S.Text>
      {options
        .filter((x) => x.is_active === "1")
        .map((item, key) => {
          return (
            <S.ContainerText key={index}>
              <input
                key={key}
                id="idCheck"
                type="checkbox"
                checked={item.value}
                onChange={(event) => onChange(event.target.checked, item.id, item.is_textual)}
              />
              <S.Text
                color="white"
                fontName="mobileBody"
                onClick={() => onChange(!item.value, item.id, item.is_textual)}
              >
                {item.option}
              </S.Text>
              {item.is_textual && item.value && (
                <S.Input
                  id={item.id}
                  value={valueField}
                  onBlur={() => handleBlur(valueField, item.id)}
                  backgroundColor={"dark"}
                  inputColor={"white"}
                  onChange={(e) => handleChange(e.target.value)}
                  autoFocus={item.id === focusedIndex}
                ></S.Input>
              )}
            </S.ContainerText>
          );
        })}
      <S.Text color="red" fontName="bodyText2">
        {messageError}
      </S.Text>
    </S.Container>
  );
};

CheckBox.propTypes = {
  idQuestion: PropTypes.number,
  title: PropTypes.string,
  index: PropTypes.string,
  isOnlyChoise: PropTypes.string,
  options: PropTypes.array,
  setValueQuestion: PropTypes.func,
};

export default CheckBox;
