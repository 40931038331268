import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: "space-between";
  background-color: ${({ theme }) => theme.color.white};
  top: ${({ top }) => top || "0px"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "500px"};
  padding: ${({ padding }) => padding || "500px"};
  gap: ${({ gap }) => gap || "24px"};
  border-radius: 8px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.25);
`;


export const Button = styled.button`
  background: ${({ theme, backgroudColor }) => theme.color[backgroudColor]};
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  border: ${({ theme, borderColor }) => theme.color[borderColor]};
  width: 100%;
  height: ${({ height }) => height || "35px"};
  border-radius: 8px 8px 8px 8px;
`;

export const Text = styled.text`
  color: ${({ theme, fontColor }) => theme.color[fontColor]};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
`;


export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
`;

