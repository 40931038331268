import React from "react";
import FormProduct from "../form";
import PropTypes from "prop-types";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";

const BannerProduct = ({ urlImage, title, subTitle, quizzes }) => {

  const isMobile = useCheckIsMobileScreen();

  return (
    <S.Container>
      <S.Wrapper
        width={"100%"}
        height={isMobile ? "450px" : "600px"}
        urlImg={urlImage}
        padding={isMobile ? "16px 16px 16px 16px" : "128px 128px 128px 128px"}
      >
        <S.ContainerText
          height={isMobile ? "259px" : "auto"}
          padding={isMobile ? "0 10px" : "40px 0"}
          width="100%"
        >
          <S.ContainerText1
            height={"auto"}
            width={"100%"}
            padding={isMobile ? "160px 0 0 0" : "150px 0 0 0"}
          >
            <S.Text
              fontName={isMobile ? "mobileHeader1" : "titleDesktop"}
              color="white"
            >
              {title}
            </S.Text>
          </S.ContainerText1>
          <S.ContainerText2>
            <S.Text
              fontName={isMobile ? "mobileBody" : "titleDesktop2"}
              color="white"
              width={"100%"}
            >
              {subTitle}
            </S.Text>
          </S.ContainerText2>
        </S.ContainerText>

        {!isMobile && (
          <S.FormContainter marginTop={"0"} marginLeft={"0"}>
            <FormProduct></FormProduct>
          </S.FormContainter>
        )}


      </S.Wrapper>
      {isMobile && (
        <S.FormContainter
        marginTop={"-250px"}
        marginLeft={"20px"}
        marginRight={"20px"}
        >
          <FormProduct></FormProduct>
        </S.FormContainter>
      )}
    </S.Container>
  );
};

BannerProduct.propTypes = {
  urlImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  quizzes: PropTypes.string,
};

export default BannerProduct;
