/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

const LongText = ({
  idQuestion,
  title,
  index,
  value,
  validation,
  setError,
  setValueQuestion,
  isOptional,
  backgroundColor = "dark",
  inputColor = "white",
  textColor = "white"
}) => {
  const [messageError, setMessageError] = useState("");
  const [valueField, setValueField] = useState(
    value !== undefined ? value : "",
  );
  const idField = "field" + index;

  useEffect(() => {
    setValueField(value);
  }, [idQuestion]);

  const handleBlur = () => {
    isEmpty();
  };

  const isEmpty = () => {
    if (!isOptional && (valueField === undefined || valueField.length === 0)) {
      setMessageError(title + " não informado");
      setError(true, index);
      focus();
      return true;
    }
    setMessageError("");
    setError(false, index);
    return false;
  };

  const focus = () => {
    const textbox = document.getElementById(idField);
    textbox.focus();
  };

  const handleChange = (value) => {
    setValueField(value);
    setValueQuestion(idQuestion, value);
  };

  return (
    <S.ContainerText key={index}>
      <S.Text color={textColor} fontName="bodyTextNegrito">
        {title}
      </S.Text>
      <S.Input
        id={idField}
        value={valueField}
        onBlur={handleBlur}
        backgroundColor={backgroundColor}
        inputColor={inputColor}
        onChange={(e) => handleChange(e.target.value)}
      ></S.Input>
      <S.Text color="red" fontName="bodyText2">
        {messageError}
      </S.Text>
    </S.ContainerText>
  );
};

LongText.propTypes = {
  idQuestion: PropTypes.number,
  title: PropTypes.string,
  index: PropTypes.string,
  value: PropTypes.string,
  validation: PropTypes.string,
  isOptional: PropTypes.bool,
  setError: PropTypes.func,
  setValueQuestion: PropTypes.func,
  backgroundColor: PropTypes.string,
  inputColor: PropTypes.string,
  textColor: PropTypes.string
};

export default LongText;
