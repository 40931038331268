import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as S from "./styles";
import SolutionsItem from "../solutionsItem";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";

const Solutions = () => {
  const { homeData } = useSelector((state) => state.home);
  const isMobile = useCheckIsMobileScreen();
  const [darkColor, setDarkColor] = useState(true);

  const consignado = "/images/banner-credito-consignado.jpeg";
  const consorcio = "/images/banner-venda-de-consorcio.jpeg";
  const recebiveis = "/images/banner-antecipacao-de-recebiveis.jpeg";
  const capitalGiro = "/images/banner-capital-de-giro.jpeg";
  const garantiaImobiliaria = "/images/banner-emprestimo-com-garantia-imobiliaria.jpeg";
  const garantiaVeiculo = "/images/banner-emprestimo-com-garantia-de-veiculos.jpeg";
  const salario = "/images/banner-antecipacao-de-salario.jpeg";
  // const salesperson = "/images/salesperson.svg";
  const fgts = "/images/banner-antecipacao-de-fgts.jpeg";

  const [boardClient, setBoardClient] = useState([]);
  const [boardCompany, setBoardCompany] = useState([]);
  const [board, setBoard] = useState(1);
  const onClick = (dark) => {
    setDarkColor(dark);
    setBoard(board === 1 ? 2 : 1);
  };

  useEffect(() => {
    if (homeData) {
      const boardClient = homeData.highlights.filter(
        (x) => x.type === "para-voce",
      );
      isMobile
        ? setBoardClient(boardClient)
        : setBoardClient(formatBoard(boardClient));
      const boardCompany = homeData.highlights.filter(
        (x) => x.type === "para-empresa",
      );
      isMobile
        ? setBoardCompany(boardCompany)
        : setBoardCompany(formatBoard(boardCompany));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  const formatBoard = (items) => {
    let item1 = null;
    let item2 = null;
    let board = [];
    for (let i = 0; i < items.length; i++) {
      if (!item1) {
        item1 = items[i];
      } else {
        item2 = items[i];
        board.push({
          item1: item1,
          item2: item2,
        });
        item1 = null;
        item2 = null;
      }
    }
    if (item1) {
      board.push({
        item1: item1,
        item2: null,
      });
    }

    return board;
  };

  const getImage = (item) => {
    const product = item.destination_route?.replace("/produto/", "");
    switch (product) {
      case "antecipacao-de-fgts":
        return fgts;
      case "venda-seu-consorcio":
        return consorcio;
      case "antecipacao-de-salario":
        return salario;
      case "credito-consignado":
        return consignado;
      case "antecipacao-de-recebiveis":
        return recebiveis;
      case "capital-de-giro":
        return capitalGiro;
      case "emprestimo-com-garantia-imobiliaria":
        return garantiaImobiliaria;
      case "emprestimo-com-garantia-de-veiculos":
        return garantiaVeiculo;
      default:
        return fgts;
    }
  };

  if (boardClient.length === 0) return;
  if (boardCompany.length === 0) return;

  const getMobile = () => {
    return (
      <S.Container
        height="auto"
        width="100%"
        padding="50px 16px 16px 16px"
        justifyContent="flex-start"
      >
        <S.Text fontName="mobileHeader2">Confira as soluções oferecidas</S.Text>
        <S.Wrapper>
          <S.Button
            background={darkColor ? "dark" : "white"}
            borderColor="dark"
            color={darkColor ? "white" : "dark"}
            onClick={() => onClick(true)}
            padding="8px 50px"
          >
            <S.Text fontName="label">Para você</S.Text>
          </S.Button>
          <S.Button
            background={darkColor ? "white" : "dark"}
            borderColor="dark"
            color={darkColor ? "dark" : "white"}
            onClick={() => onClick(false)}
            padding="8px 40px"
          >
            <S.Text fontName="label">Para empresa</S.Text>
          </S.Button>
        </S.Wrapper>
        {board === 1 && (
          <S.Wrapper flexDirection="column" height="auto">
            {boardClient.map((item, key) => {
              return (
                <SolutionsItem
                  key={key}
                  title={item.title}
                  link={item.destination_route}
                  img={getImage(item, key)}
                  body={item.description}
                  item={item}
                ></SolutionsItem>
              );
            })}
          </S.Wrapper>
        )}
        {board === 2 && (
          <S.Wrapper flexDirection="column" height="auto">
            {boardCompany.map((item, key) => {
              return (
                <SolutionsItem
                  key={key}
                  title={item.title}
                  link={item.destination_route}
                  img={getImage(item, key)}
                  body={item.description}
                  item={item}
                ></SolutionsItem>
              );
            })}
          </S.Wrapper>
        )}
      </S.Container>
    );
  };
  const getDesktop = () => {
    return (
      <S.Container height="auto" width="100%" padding="60px 128px 24px 128px">
        <S.Wrapper margin="0 0 20px 0">
          <S.Wrapper justifyContent="flex-start" height="48px">
            <S.Text fontName="buttonDeskTop">
              Confira as soluções oferecidas
            </S.Text>
          </S.Wrapper>
          <S.Wrapper justifyContent="flex-end" height="48px">
            <S.Button
              background={darkColor ? "dark" : "white"}
              borderColor="dark"
              color={darkColor ? "white" : "dark"}
              onClick={() => onClick(true)}
              padding="10px 50px"
            >
              <S.Text
                color={darkColor ? "white" : "dark"}
                fontName="mobileHeader2"
              >
                Para você
              </S.Text>
            </S.Button>
            <S.Button
              background={darkColor ? "white" : "dark"}
              borderColor="dark"
              color={darkColor ? "dark" : "white"}
              onClick={() => onClick(false)}
              padding="10px 50px"
            >
              <S.Text
                color={darkColor ? "dark" : "white"}
                fontName="mobileHeader2"
              >
                Para empresa
              </S.Text>
            </S.Button>
          </S.Wrapper>
        </S.Wrapper>
        {board === 1 &&
          // eslint-disable-next-line array-callback-return
          boardClient.map((item, key) => {
            if (item.item1 && item.item2) {
              return (
                <S.Wrapper key={key} width="100%" height="auto">
                  <SolutionsItem
                    key={key}
                    title={item.item1.title}
                    link={item.item1.destination_route}
                    img={getImage(item.item1)}
                    body={item.item1.description}
                    item={item.item1}
                  ></SolutionsItem>
                  <SolutionsItem
                    key={key}
                    title={item.item2.title}
                    link={item.item2.destination_route}
                    img={getImage(item.item2)}
                    body={item.item2.description}
                    item={item.item2}
                  ></SolutionsItem>
                </S.Wrapper>
              );
            }
            if (!item.item2) {
              return (
                <S.Wrapper key={key} width="100%" height="auto">
                  <SolutionsItem
                    key={key}
                    title={item.item1.title}
                    link={item.item1.destination_route}
                    img={getImage(item.item1)}
                    body={item.item1.description}
                    item={item.item1}
                  ></SolutionsItem>
                </S.Wrapper>
              );
            }
          })}
        {board === 2 &&
          // eslint-disable-next-line array-callback-return
          boardCompany.map((item, key) => {
            if (item.item1 && item.item2) {
              return (
                <S.Wrapper key={key} width="100%" height="auto">
                  <SolutionsItem
                    key={key}
                    title={item.item1.title}
                    link={item.item1.destination_route}
                    img={getImage(item.item1)}
                    body={item.item1.description}
                    item={item.item1}
                  ></SolutionsItem>
                  <SolutionsItem
                    key={key}
                    title={item.item2.title}
                    link={item.item2.destination_route}
                    img={getImage(item.item2)}
                    body={item.item2.description}
                    item={item.item2}
                  ></SolutionsItem>
                </S.Wrapper>
              );
            }
            if (!item.item2) {
              return (
                <S.Wrapper key={key} width="50%">
                  <SolutionsItem
                    key={key}
                    title={item.item1.title}
                    link={item.item1.destination_route}
                    img={getImage(item.item1)}
                    body={item.item1.description}
                    item={item.item1}
                  ></SolutionsItem>
                </S.Wrapper>
              );
            }
          })}
      </S.Container>
    );
  };
  return isMobile ? getMobile() : getDesktop();
};
export default Solutions;
