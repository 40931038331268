import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  width: 100%;

  background: ${({ theme }) => theme.color.dark};
  padding: 18px 20px 18px 20px;
`;

export const Image = styled.img`
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
`;

export const Text = styled.text`
  color: ${({ theme }) => theme.color.white};
  font: ${({ theme, fontName }) => theme.typography[fontName]};
  text-align: left;
  padding: ${({ padding }) => padding || "0 0"};
`;

export const ContainerStep = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: "start";
  width: 100%;
  height: 15px;
  background: ${({ theme }) => theme.color.dark};
  padding: 0px 16px 0px 0px;
`;
export const Button = styled.button`
  width: ${({ width }) => width || "104px"};
  height: 5px;
  border-radius: 8px;
  border: 0.01rem solid ${({ theme, color }) => theme.color[color]};
  background: ${({ theme, color }) => theme.color[color]};
  gap:16px;
  margin: 8px 8px 0px 0px;
`;
