import axios from "axios";
import types from "../types";

export const getTermo = () => {
  return async (dispatch) => {
    const url = `${process.env.REACT_APP_BFF}term`;
    try {
      axios
        .get(url, { timeout: 300000, withCredentials: false })
        .then((response) => {
          dispatch({
            type: types.GET_TERMO,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };
};
