import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/home';
import Question from '../pages/questionnaire';
import Finish from '../pages/finish';
import Product from '../pages/product';
import Termo from '../pages/termo';

const Router = () => {
  return (
    <div data-testid="idRoutes">
      <Routes>
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/question" element={<Question />} />
        <Route exact path="/finish" element={<Finish />} />
        <Route exact path="/product" element={<Product />} />
        <Route exact path="/termo" element={<Termo />} />
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
};

export default Router;
