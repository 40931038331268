import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setStep, setNumberStep } from "../../../redux/quiz/actions";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import * as S from "./styles";

const HeaderQuestionnaire = ({ title, stepInitial, numberSteps, numberStepsHeader }) => {

  const isMobile = useCheckIsMobileScreen();
  const { step } = useSelector((state) => state.quiz);
  const [stepShow, setStepShow] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStep(stepInitial));
    dispatch(setNumberStep(numberSteps));
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStepShow((step - stepInitial) + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // const onClick = (index, stepClick) => {
  //   if (hasError.find(x=> x === true)) {
  //     return;
  //   }
  //   setStepShow((index - stepInitial) + 1);
  //   dispatch(setStep(index));
  //   dispatch(sendQuestionnaire(questionnaire));
  // };
  const getSteps = () => {
    const steps = [];
    for (let i = stepInitial; i <= numberSteps; i++) {
      steps.push(
        <S.Button
          width={isMobile ? "100%" : "204px"}
          key={i}
          color={i === step ? "beige" : "white"}
        />,
      );
    }
    return steps;
  };
  if (step === -1) return;

  return (
    <S.Container >
      <S.Text fontName="mobileHeader2">{title}</S.Text>
      <S.Text fontName="bodyText2">{`Etapa ${stepShow} de ${numberStepsHeader}`}</S.Text>
      <S.ContainerStep>{getSteps()}</S.ContainerStep>
    </S.Container>
  );
};

HeaderQuestionnaire.propTypes = {
  title: PropTypes.string,
  stepInitial: PropTypes.number,
  numberSteps: PropTypes.number,
  numberStepsHeader: PropTypes.number,
};

export default HeaderQuestionnaire;
