import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as S from "./styles";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import { clearQuestionnaire } from "../../../redux/quiz/actions";

const FinalQuestionnaire = () => {
  const dispatch = useDispatch();
  const isMobile = useCheckIsMobileScreen();
  const navigate = useNavigate();

  const onClick = () => {
    dispatch(clearQuestionnaire());
    navigate("/home");
  };

  return (
    <S.Container justifyContent="start">
      <S.ContainerText gap="16px" height="90%" padding="8px 8px 8px 16px">
        <S.Text
          fontColor="white"
          fontName={isMobile ? "mobileHeader2" : "titleDesktop"}
        >
          Obrigado!
        </S.Text>
        <S.ContainerText gap="4px" height="10%">
          {isMobile && (
            <>
              <S.Text fontColor="white" fontName="mobileBody">
                Em breve nossa equipe retornará seu
              </S.Text>
              <S.Text fontColor="white" fontName="mobileBody">
                contato com mais informações sobre os próximos passos.
              </S.Text>
            </>
          )}
          {!isMobile && (
            <S.Text fontColor="white" fontName="bodyText1">
              Em breve nossa equipe retornará seu contato com mais informações sobre os próximos passos.
            </S.Text>
          )}
        </S.ContainerText>
      </S.ContainerText>
      <S.ContainerButton>
        <S.Button
          backgroudColor="beige"
          fontColor="dark"
          borderColor="dark"
          height={isMobile ? "35px" : "48px"}
          width={isMobile ? "100%" : "100%"}
          onClick={() => onClick()}
        >
          <S.Text
            fontName={isMobile ? "button3" : "mobileHeader2"}
            fontColor="white"
          >
            Voltar para a página inicial
          </S.Text>
        </S.Button>
      </S.ContainerButton>
    </S.Container>
  );
};

export default FinalQuestionnaire;
