import initialState from "../initialState";
import types from "../types";

const termoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_TERMO:
      return {
        ...state,
        termoData: payload.termo
      };
    default:
      return state;
  }
};
export default termoReducer;
