import initialState from "../initialState";
import types from "../types";

const termoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CLEAR_PHONE:
      return {
        ...state,
        talkToUs: null,
        phone: null
      };
    case types.GET_PHONE:
      return {
        ...state,
        talkToUs: payload.talkToUs,
        phone: payload.phone
      };
    default:
      return state;
  }
};
export default termoReducer;
